import React, { PureComponent } from 'react';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';
import { GenTooltipIcon } from '../utils/function';
import { Timer } from '@mui/icons-material';
import { red } from '@mui/material/colors';
import Grid from '@mui/material/Grid';

class CountDown extends PureComponent {
  state = {
    timer: null,
    countDown: 0,
  }

  count = 0;

  componentDidUpdate(prevProps) {
    if (this.props.timer !== prevProps.timer) {
      clearInterval(this.count);
      this.count = setInterval(this.showTimer, 1000);
    }
  }

  componentDidMount() {
    this.showTimer();
    this.count = setInterval(this.showTimer, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.count);
  }

  showTimer = () => {
    const { setData } = this.props;
    const countDown = dayjs(this.props.timerEnd).diff(dayjs());
    this.setState({
      countDown
    });
    let timer;

    if (countDown <= 0) {
      clearInterval(this.count);
      timer = 'Délai dépassé';
      this.setState({
        countDown: 0
      });
      setData('paymentSent', false);
    }
    else {
      timer = dayjs.utc(countDown).format('HH:mm:ss');
      setData('paymentSent', true);
    }
    this.setState({ timer });
  }

  render() {
    const { timer: timerProps } = this.props;
    const { timer } = this.state;

    return (
      <div>
        <Grid container wrap="nowrap" alignItems="center">
          {timerProps
            ? <React.Fragment>
              <Grid item xs={4} container alignItems="center">
                <GenTooltipIcon
                  Icon={Timer}
                  color={red}
                />
              </Grid>
              <Grid item xs={8}>
                {timer || 'Veuillez patienter'}
              </Grid>
            </React.Fragment> : null
          }
        </Grid>
      </div>
    );
  }
}

export default CountDown;
