import React, { PureComponent } from 'react';
import api from '../api';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import notifSystem from '../notifSystem';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc =
  `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

export class RenderHTML extends PureComponent {
  state = {
    content: '',
    open: false,
  };
  componentDidMount() {
    this.getContentHTML(this.props);
  }

  componentWillUnmount() {
    this.props.callback(false);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getContentHTML(nextProps);
  }

  handleClose = () => {
    this.props.callback(false);
  };

  getContentHTML = async (props) => {
    const {
      type, method, info, open,
      data, renderFile, requestMethod = 'post'
    } = props;
    let obj = {
      method: method,
      info: info,
      data: data,
    };
    if (open || renderFile) {
      try {
        await api
          .all(type)[requestMethod](obj)
          .then((res) => {
            this.setState({
              content: res.body().data(),
              open: open,
            });
          });
      } catch (e) {
        notifSystem.error('Erreur', 'Document non récupéré');
        throw e;
      }
    } else {
      this.setState({
        open: open,
      });
    }
  };

  onDocumentLoadSuccess = (document) => {
    const { numPages } = document;
    this.setState({
      numPages,
    });
  };

  renderFile = () => {
    const { content } = this.state;
    const renderFile = this.props.renderFile;

    const isMobile =
      !!navigator.userAgent &&
      (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i).test(
        navigator.userAgent
      );

    const data = `data:application/pdf;base64,${content.replace(
      /,/g,
      ''
    )}`;
    return (
      <div
        style={{
          marginTop: renderFile ? 0 : 64,
          height: '100%',
          width: '100%',
          overflow: 'auto',
        }}
      >
        {!isMobile ? (
          <iframe
            width="450px"
            height="450px"
            style={{ height: '100%', width: '100%', overflow: 'auto' }}
            title="pdf"
            name="here"
            src={data}
            type="application/pdf"
          ></iframe>
        ) : (
          !!content && (
            <Document
              file={data}
              onLoadSuccess={this.onDocumentLoadSuccess}
              error="Document non trouvé."
            >
              {Array.from(new Array(this.state.numPages || 0), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  scale={0.7}
                />
              ))}
            </Document>
          )
        )}
      </div>
    );
  };
  render() {
    return !this.props.renderFile ? (
      <Dialog
        fullScreen
        open={this.state.open}
        onClose={this.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar enableColorOnDark>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={this.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {this.renderFile()}
      </Dialog>
    ) : (
      this.renderFile()
    );
  }
}

export default RenderHTML;
