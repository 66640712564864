import React, { PureComponent } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Money from '../../utils/Money';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import CountDown from '../CountDown';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';
import { Tooltip } from '@mui/material';
import {
  paths,
  sendPaymentCBArtisanClient,
  verification
} from '../../actions/proposition';
import notifSystem from '../../notifSystem';

class PayButton extends PureComponent {
  state = {
    paymentSent: false,
  }

  setData(path, value) {
    this.props.setData(path, value );
    this.setState({ [path]: value });
  }

  sendProposition = (key, cb) => {
    try {
      const { merge, mail, tel, montant, artisanId, to } = this.props;
      const finalPrice = merge.getIn(paths.finalPrice)
      || merge.getIn(paths.propsFinalPrice)
      || merge.getIn(paths.announcedPrice);
      if (finalPrice) {
        verification({
          merge,
        }, key, () => cb({
          id: merge.get('id'),
          mail,
          tel,
          artisanId,
          montant,
          to,
        })
        );
      }
    } catch (error) {
      notifSystem.error('Erreur', 'Informations n\'ont pas été sauvegardées');
    }
  };

  handleClickPayment = (data) => {
    sendPaymentCBArtisanClient(data).then(() => {
      this.props.setData(['date', 'demandePayment'], new Date());
      this.props.updateState({
        activeStep: 1,
        isClicked: true,
        newDatePaiement: new Date(),
      });
      this.setState({
        newDatePaiement: new Date(),
      });
    });
  };

  handleClick(paymentEnabled) {
    if (paymentEnabled) {
      this.sendProposition('payment', this.handleClickPayment);
    }
  }

  render() {
    const {
      inter,
      withAdvance = true,
      title,
      activeStep = 0
    } = this.props;
    const { paymentSent, newDatePaiement } = this.state;
    const timer = newDatePaiement || inter.getIn(['date', 'demandePayment']);
    const timerEnd = dayjs(timer).add(2, 'm');
    const titleButton = title ||
    `${activeStep > 0 ? 'Renvoyer' : 'Envoyer'} un lien de paiement`;
    const isAfter = paymentSent ?
      false : dayjs().isAfter(timerEnd);
    const paymentEnabled = !timer || isAfter;

    return (
      <Grid item xs={12} container justifyContent="center" spacing={2}>
        <Grid item lg={12} xs={12} align="center">
          {!inter.getIn(paths.isRegle) && (
            <Grid
              item
              xs={12}
              lg={4}
              sm={6}
              md={4}
              children={
                <Tooltip
                  title={paymentEnabled ?
                    '' : 'Un lien de paiement a déjà été envoyé'
                  }
                  disableInteractive
                  enterTouchDelay={0}>
                  <Button
                    onClick={() => this.handleClick(paymentEnabled)}
                    fullWidth
                    color="primary"
                    variant={paymentEnabled ? 'contained' : 'outlined'}
                  >
                    { timer && !isAfter ? <CountDown
                      timer={timer}
                      timerEnd={timerEnd}
                      setData={(path, value) => this.setData(path, value)}
                    /> : titleButton}
                  </Button>
                </Tooltip>
              }
            />
          )}
        </Grid>
        {!!inter.getIn(paths.advancePayment) && withAdvance && (
          <Grid
            justifyContent="center"
            alignContent="center"
            key={0}
            item
            lg={4}
            xs={12}
            sm={6}
            md={4}
            container
            children={[
              <Grid
                key={0}
                item
                xs={1}
                lg={1}
                children={<InfoIcon color="action" style={{ fontSize: 20 }} />}
              />,
              <Grid
                key={1}
                item
                xs
                lg={4}
                md={9}
                children={
                  <Typography
                    style={{
                      fontSize: 15,
                      fontWeight: 'bold',
                    }}
                    variant="caption"
                    children={`Acompte déjà payé de ${Money.toString(
                      inter.getIn(paths.advancePayment),
                      true
                    )}`}
                  />
                }
              />,
            ]}
          />
        )}
      </Grid>
    );
  }
}

export default PayButton;
