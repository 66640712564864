import React from 'react';
import componentLoader from '../../componentLoader';

export const InterFromMobile = React.lazy(() =>
  componentLoader(() => import('./InterFromMobile'))
);
export const PropositionArtisan = React.lazy(() =>
  componentLoader(() => import('./PropositionArtisan'))
);
export const Propositions = React.lazy(() =>
  componentLoader(() => import('./Propositions'))
);
export const PropositionsDetail = React.lazy(() =>
  componentLoader(() => import('./PropositionsDetail'))
);
export const Payment = React.lazy(() =>
  componentLoader(() => import('./Payment'))
);
export const Faq = React.lazy(() =>
  componentLoader(() => import('./Faq'))
);
export const AttestationArtisan = React.lazy(() =>
  componentLoader(() => import('./AttestationArtisan'))
);
export const ArtisanHolidays = React.lazy(() =>
  componentLoader(() => import('./ArtisanHolidays'))
);
export const ArtisanUpload = React.lazy(() =>
  componentLoader(() => import('./ArtisanUpload'))
);
export const InterventionListArtisan = React.lazy(() =>
  componentLoader(() => import('./InterventionsListe'))
);
export const Relance = React.lazy(() =>
  componentLoader(() => import('../candidats/Relance'))
);
export const CandidatCancel = React.lazy(() =>
  componentLoader(() => import('../candidats/CandidatCancel'))
);
export const CandidatMenu = React.lazy(() =>
  componentLoader(() => import('../candidats/CandidatMenu'))
);

export const SignPages = React.lazy(() =>
  componentLoader(() => import('../../components/SignPages'))
);

export const SousMetiers = React.lazy(() =>
  componentLoader(() => import('./SousMetiers'))
);

export const Profile = React.lazy(() =>
  componentLoader(() => import('./Profile'))
);
