import React from 'react';
import store from '../store';
import {
  data as historyCat
} from '@lba-dev/package.local-globals/historyCat';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';
import filesize from 'filesize';
import Tooltip from '@mui/material/Tooltip';

export const deepCopy = obj => {
  let copy;
  if (obj instanceof Array) {
    copy = [...obj];
    copy.forEach((e, i) => {
      copy[i] = deepCopy(e);
    });
  } else if (typeof obj === 'object') {
    copy = { ...obj };
    Object.keys(obj).forEach(key => {
      obj[key] = deepCopy(obj[key]);
    });
  }
  return copy || obj;
};

export function getLogin(_id, def) {
  const user = store.getState().users.find(e => e._id === _id);
  return user ? user.login : (def || 'automatique');
}

export function getPseudo(_id) {
  const user = store.getState().users.find(e => e._id === _id);
  return user ? user.alias : '';
}

export function getType(i) {
  const type = historyCat.find(e => e._id === i);
  return type ? type.name : '';
}

export function getdayjsOrDate(date) {
  return `${dayjs(date).format('L')} à
    ${dayjs(date).format('HH[h]mm')}`;
}

export const getSize = (ret) => {
  let tmpSize = 0;
  ret.forEach(element => {
    tmpSize += element.size;
  });
  tmpSize = filesize(tmpSize, { exponent: 2 });
  return tmpSize;
};

export const isMaxSizeOk = (files, maxSize) => {
  const ret = Number.parseFloat(getSize(files));
  maxSize = Number.parseFloat(maxSize, 10);
  return (ret >= 0 && ret < maxSize);
};

export const isMaxFileOk = (length, maxFile) =>
  (length >= 0 && length <= maxFile);

export const GenTooltipIcon = ({
  Icon,
  text,
  color,
  colorProps,
  fontSize = 20,
  colorNumber = 500,
  extraStyle = {},
  ...props
}) => (
  <Tooltip
    disableInteractive
    title={text ? <span>{text}</span> : ''}
    children={
      <Icon
        {...props}
        color={colorProps}
        style={{ color: color[colorNumber], fontSize, ...extraStyle }}
      />
    }
  />
);
