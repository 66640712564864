export default {
  artisans: 'artisans',
  candidats: 'candidats',
  devis: 'devis',
  interventions: 'interventions',
  login: 'login',
  equipes: 'equipes',
  services: 'services',
  users: 'users',
  signature: 'signature',
  campagnes: 'campagnes',
  paiements: 'paiements',
  propositions: 'propositions',
  metiersDevis: 'metiersDevis',
  count: 'count'
};
