import React from 'react';
import { Navigate } from 'react-router-dom';
import {
  InterFromMobile,
  PropositionArtisan,
  AttestationArtisan,
  ArtisanHolidays,
  ArtisanUpload,
  InterventionListArtisan,
  Relance,
  CandidatCancel,
  CandidatMenu,
  Payment,
  SignPages,
  PropositionsDetail,
  Propositions,
  Faq,
  Profile,
  SousMetiers,
} from '../views/artisans';

import {
  AgentTabs as agentTabs,
  MapCalls,
  InterventionService,
  StatsServices,
} from '../views/agents';
import { S_AGENT, S_ARTISAN } from '@lba-dev/package.local-globals/services';
import { HORAIRES, HOLIDAYS } from '@lba-dev/package.local-globals/AbsenDispo';
import {
  P_DEVIS,
  P_UPLOAD,
  P_ATT,
  P_OS,
  P_DISPO,
  P_PAYMENT,
  P_CANDIDAT,
  data as nPages,
} from '@lba-dev/package.local-globals/nPages';

import {
  P_SERV,
  P_MAP,
  P_STAT,
  data as agentPages,
} from '@lba-dev/package.local-globals/agentPages';

const pagesTypes = {
  [S_ARTISAN]: nPages,
  [S_AGENT]: agentPages
}

const artisanDevis = {
  path: '/artisanDevis',
  component: InterFromMobile,
};

export default (props) =>
  ({
    [S_ARTISAN]: [
      {
        path: '/',
        component: Propositions,
        children: () => <Navigate to={'/missions'} />,
      },
      {
        path: '/artisanOS',
        component: InterventionListArtisan,
        routes: [artisanDevis],
        id: P_DEVIS,
      },
      artisanDevis,
      { path: '/candidat', component: CandidatMenu, id: P_CANDIDAT },
      { path: '/candidat/candidatRelance', component: Relance, id: P_CANDIDAT },
      {
        path: '/candidat/candidatCancel',
        component: CandidatCancel,
        id: P_CANDIDAT,
      },
      { path: '/payment', component: Payment, id: P_PAYMENT },
      { path: '/artisanIntervention/:filter', component: PropositionArtisan },
      { path: '/artisanIntervention', component: PropositionArtisan },
      { path: '/missions', component: Propositions, id: P_OS },
      { path: '/missions/news', component: Propositions, id: P_OS },
      { path: '/missions/ongoing', component: Propositions, id: P_OS },
      {
        path: '/missions/history',
        component: Propositions,
        id: P_OS,
      },
      { path: '/missions/:id', component: PropositionsDetail, id: P_OS },
      {
        path: '/missions/:id/:filter',
        component: PropositionsDetail,
      },
      { path: '/artisanAttestation', component: AttestationArtisan, id: P_ATT },
      {
        path: '/availability',
        component: ArtisanHolidays,
        routes: [
          {
            path: '/disponible',
            component: ArtisanHolidays,
            componentProps: { type: HORAIRES },
          },
          {
            path: '/absence',
            component: ArtisanHolidays,
            componentProps: { type: HOLIDAYS },
          },
        ],
        id: P_DISPO,
      },
      { path: '/documents', component: ArtisanUpload, id: P_UPLOAD },
      { path: '/sign/:type', component: SignPages },
      { path: '/faq', component: Faq },
      { path: '/profile', component: Profile },
      { path: '/sousMetiers', component: SousMetiers },
    ],
    [S_AGENT]: [
      { path: '/', component: InterventionService, id: P_SERV },
      { path: '/services', component: InterventionService, id: P_SERV },
      { path: '/map', component: MapCalls, id: P_MAP },
      { path: '/statistiques', component: StatsServices, id: P_STAT },
    ].map((e) => ({ ...e, component: agentTabs(e.component) })),
  }[props.auth.decodedToken.service || S_ARTISAN]
    .filter((e) =>
      props.auth.decodedToken.pages.some((a) => {
        const pagesArr = pagesTypes[
          props.auth.decodedToken.service || S_ARTISAN
        ] || [];
        const { route, alias } = pagesArr.find((p) => p._id === a.id) || {};
        return (
          ((route || (alias && alias.some((p) => e.path.includes(p)))) &&
            a.enable) ||
          ['/', '/faq', '/sign/:type', '/profile', '.well-known'].includes(
            e.path
          )
        );
      })
    )
    .reduce((v, c) => [...v, c, ...(c.routes || [])], []));
